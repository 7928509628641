import { ExportBatchAssetsBody, PortalData } from "shared/types/assetExporter";
import { User } from "redux/auth/auth.slice";
import { getMappingDataForExportPortal } from "./getMappingDataForExportPortal";
import {
  AdInfoUpdates,
  AdType,
  ExportDestination,
  PortalOptionMatches,
  RecommendedColumnMatches,
} from "../types";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";

interface GetExportAssetBatchesBody {
  selectedRows: string[];
  selectedAssetBatches: string[];
  feedId: string;
  exportDestination: ExportDestination;
  user: User;
  columnMatches?: RecommendedColumnMatches;
  adInfoUpdates?: AdInfoUpdates;
  adType?: AdType | null;
  portalOptionMatches?: PortalOptionMatches;
  portalData?: PortalData;
  filterArgs?: IFilter[];
  searchValue?: string;
  selectAll: boolean;
}

export const getExportAssetBatchesBody = ({
  selectedRows,
  selectedAssetBatches,
  feedId,
  user,
  exportDestination,
  columnMatches,
  adInfoUpdates,
  adType,
  portalOptionMatches,
  portalData,
  filterArgs,
  searchValue,
  selectAll,
}: GetExportAssetBatchesBody): ExportBatchAssetsBody => {
  const baseExportBody: ExportBatchAssetsBody = {
    feedId,
    createdBy: user.email,
    selectedRows: selectedRows,
    assetBatchIds: selectedAssetBatches,
    exportType: exportDestination,
    filters: filterArgs,
    search: searchValue,
    selectAll,
  };

  if (exportDestination === ExportDestination.AdLibrary && adType) {
    return {
      ...baseExportBody,
      columnMatches,
      adType,
      updates: adInfoUpdates,
    };
  } else if (
    exportDestination === ExportDestination.GoogleSpreadsheet &&
    portalOptionMatches
  ) {
    const mappingDataForExportPortal =
      getMappingDataForExportPortal(portalOptionMatches);

    return {
      ...baseExportBody,
      mappingDataForExportPortal,
      instanceName: process.env.REACT_APP_AV2_CLIENT,
    };
  } else if (exportDestination === ExportDestination.Portal) {
    return {
      ...baseExportBody,
      portalData,
    };
  } else return baseExportBody;
};
